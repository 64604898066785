import { NavLink } from '@remix-run/react';
import { ReactNode } from 'react';
import classNames from 'classnames';

export default function Link(props: {
  onClick?: () => void;
  children: ReactNode;
  to?: string;
  back?: boolean;
  icon?: ReactNode;
  left?: boolean;
}) {
  const iconWrapper = props.icon ? (
    <div
      className={classNames('w-3 h-3 inline-block', {
        'ml-1': props.left !== true,
        'mr-1': props.left === true,
      })}
    >
      {props.icon}
    </div>
  ) : (
    <></>
  );
  const classes =
    'text-blue-600 font-medium group-focus-visible:bg-white group-focus-visible:ring-2 group-focus-visible:ring-theme-secondary group-focus-visible:ring-offset-4 rounded inline-flex items-center hover:text-black cursor-pointer';

  if (props.to?.includes('http')) {
    return (
      <a href={props.to} className={classes} target="_blank">
        {props.left === true && iconWrapper}
        {props.children}
        {props.left !== true && iconWrapper}
      </a>
    );
  }

  if (props.to) {
    return (
      <NavLink to={props.to} className={classes}>
        {props.left === true && iconWrapper}
        {props.children}
        {props.left !== true && iconWrapper}
      </NavLink>
    );
  }

  return (
    <span
      onClick={(e) => {
        e.preventDefault();

        if (props.onClick) {
          props.onClick();
        } else if (props.back) {
          history.back();
        }
      }}
      className={classes}
    >
      {props.left === true && iconWrapper}
      {props.children}
      {props.left !== true && iconWrapper}
    </span>
  );
}
